import React, { useState, useEffect } from 'react';
import { db } from '../FirebaseConfig';
import { collection, doc, getDoc } from 'firebase/firestore';
import VillaVideo from '../Assets/VillaVideo.mp4';
import throphy from '../Assets/trophy.gif';
import customerService from '../Assets/CustomService.gif';
import support from '../Assets/24-7.gif';
import Loading from './Loading';
import Contact from './Contact';
import '../Css/Home.css';

const Home = () => {
  const [headerData, setHeaderData] = useState({ title: '', subtitle: '', button: '' });
  const [advantages, setAdvantages] = useState([]);
  const [services, setServices] = useState([]);
  const [packages, setPackages] = useState([]);
  const [contactInfo, setContactInfo] = useState({ email: '', phone: '' });
  const [ourStory, setOurStory] = useState('');
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePackage, setActivePackage] = useState(null);
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const docRefs = {
          header: doc(collection(db, 'settings'), 'header'),
          advantages: doc(collection(db, 'settings'), 'advantages'),
          services: doc(collection(db, 'settings'), 'services'),
          contact: doc(collection(db, 'settings'), 'contact'),
          ourStory: doc(collection(db, 'settings'), 'ourStory'),
          testimonials: doc(collection(db, 'settings'), 'testimonials'),
          packages: doc(collection(db, 'settings'), 'packages')
        };

        const docs = await Promise.all(Object.values(docRefs).map(ref => getDoc(ref)));

        const [headerData, advantagesData, servicesData, contactData, ourStoryData, testimonialsData, packagesData] = docs.map(doc => doc.exists() ? doc.data() : null);

        if (headerData) setHeaderData(headerData);
        if (advantagesData) setAdvantages(advantagesData.items.filter(item => item.title && item.description));
        if (servicesData) setServices(servicesData.items.filter(item => item.title && item.description));
        if (contactData) setContactInfo(contactData);
        if (ourStoryData) setOurStory(ourStoryData.story);
        if (testimonialsData) setTestimonials(testimonialsData.items.filter(item => item.text && item.author));
        if (packagesData) setPackages(packagesData.items);

      } catch (error) {
        console.error('Error fetching data: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    const testimonialInterval = setInterval(() => {
      setCurrentTestimonialIndex(prevIndex => (prevIndex + 1) % testimonials.length);
      setProgress(0);
    }, 15000); // Change every 15 seconds

    const progressInterval = setInterval(() => {
      setProgress(prevProgress => (prevProgress < 100 ? prevProgress + 1 : 0));
    }, 150); // Update progress bar every 150ms

    return () => {
      clearInterval(testimonialInterval);
      clearInterval(progressInterval);
    };
  }, [testimonials.length]);

  const togglePackage = (index) => {
    setActivePackage(activePackage === index ? null : index);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='home'>
      <header className="header">
        <div className='text' id='home'>
          {headerData.title && <h1>{headerData.title}</h1>}
          {headerData.subtitle && <p>{headerData.subtitle}</p>}
          {headerData.button && 
            <a href="/#contact" className="button">
              <button>{headerData.button}</button> 
            </a>}
           
            <svg width="0" height="0">
              <defs>
                <clipPath id="blobClip">
                  <path d="M45.5,-53.9C60.3,-51.8,74.7,-40.4,77.3,-26.5C79.8,-12.6,70.6,3.7,64.9,21C59.2,38.4,56.9,56.8,46.7,59.7C36.4,62.6,18.2,50,3.4,45.4C-11.5,40.7,-23,44.1,-32.3,40.9C-41.5,37.7,-48.5,27.9,-56.7,15.4C-65,2.9,-74.5,-12.2,-73.7,-26.7C-72.8,-41.3,-61.5,-55.2,-47.5,-57.6C-33.5,-59.9,-16.8,-50.7,-0.7,-49.7C15.3,-48.7,30.6,-56,45.5,-53.9Z" transform="translate(320, 260) scale(4.5)" />                
                </clipPath>
              </defs>
            </svg>

        </div>
        <div className="video-container">
          <video className="header-video" src={VillaVideo} autoPlay loop muted playsInline />
        </div>
      </header>

      {advantages.length > 0 && (
        <section className="advantages">
          <h2>Our Advantages</h2>
          <ul>
            {advantages.map((advantage, index) => (
              <li className="gif-container" key={index}>
                <img src={index === 0 ? throphy : index === 1 ? support : customerService} alt={advantage.title} className="gif" />
                <h3>{advantage.title}</h3>
                <p>{advantage.description}</p>
              </li>
            ))}
          </ul>
        </section>
      )}

      {services.length > 0 && (
        <section className="services" id="services">
          <h2>Our Services</h2>
          <ul>
            {services.map((service, index) => (
              <li key={index}>
              <i className={`fi ${
                index === 0 ? 'fi-sr-sparkles' :
                index === 1 ? 'fi-bs-holding-hand-gear' :
                index === 2 ? 'fi-rr-flower-butterfly' :
                index === 3 ? 'fi-br-swimmer' :
                index === 4 ? 'fi-sr-rent' :
                index === 5 ? 'fi-sr-bug-slash' :
                index === 6 ? 'fi-ss-user-headset' :
                index === 7 ? 'fi-rr-customer-care' :
                'fi-sr-megaphone'
              }`}></i>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </li>
            ))}
          </ul>
        </section>
      )}

<section className="packages">
  <h2>Our Packages</h2>
  <ul className='big_list'>
    {packages.map((packageItem, index) => (
      <li
        onClick={() => togglePackage(index)}
        key={index}
        className={index === 0 ? 'basic' : index === 1 ? 'premium' : index === 2 ? 'luxury' : ''}
      >
        <span className='package-name'>{packageItem.name}</span>
        <ul className={`package-details ${activePackage === index ? 'active' : ''}`}>
          {packageItem.items.map((item, itemIndex) => (
            <li key={itemIndex}>{item}</li>
          ))}
        </ul>
      </li>
    ))}
  </ul>
</section>


      {testimonials.length > 0 && (
        <section className="testimonials">
          <h2>What customers say about us?</h2>
          <div className="testimonial">
            {testimonials[currentTestimonialIndex] && (
              <>
                <p>"{testimonials[currentTestimonialIndex].text}" - {testimonials[currentTestimonialIndex].author}</p>
                <div className="progresss-bar">
                  <div className="progresss" style={{ width: `${progress}%` }}></div>
                </div>
              </>
            )}
          </div>
        </section>
      )}

      <Contact contactInfo={contactInfo} />

      {ourStory && (
        <section className="our-story" id='Our story'>
          <h2>Our Story</h2>
          <p>{ourStory}</p>
        </section>
      )}
    </div>
  );
}

export default Home;
