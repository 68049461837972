import React, { useState, useEffect } from 'react';
import { db, auth, provider } from '../FirebaseConfig'; // Import necessary Firebase functions and variables from firebaseConfig
import { collection, doc, getDoc, setDoc } from 'firebase/firestore'; // Import necessary Firestore functions
import { signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap'; // Add react-bootstrap for tabs
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Css/Admin.css';

function Admin() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  // State for the form inputs
  const [headerTitle, setHeaderTitle] = useState('');
  const [headerSubtitle, setHeaderSubtitle] = useState('');
  const [headerButton, setHeaderButton] = useState('');
  const [advantages, setAdvantages] = useState([]);
  const [services, setServices] = useState([]);
  const [contactInfo, setContactInfo] = useState({ email: '', phone: '' });
  const [ourStory, setOurStory] = useState('');
  const [testimonials, setTestimonials] = useState([]);
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const userEmail = user.email;
        if ( userEmail === 'chen.lister2@gmail.com' || userEmail === 'cncv159753@gmail.com' ) {
          setUser(user);
          fetchData();
        } else {
          auth.signOut();
          navigate('/');
        }
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const fetchData = async () => {
    try {
      const headerDoc = await getDoc(doc(collection(db, 'settings'), 'header'));
      if (headerDoc.exists()) {
        const data = headerDoc.data();
        setHeaderTitle(data.title);
        setHeaderSubtitle(data.subtitle);
        setHeaderButton(data.button);
      }

      const advantagesDoc = await getDoc(doc(collection(db, 'settings'), 'advantages'));
      if (advantagesDoc.exists()) {
        setAdvantages(advantagesDoc.data().items);
      }

      const servicesDoc = await getDoc(doc(collection(db, 'settings'), 'services'));
      if (servicesDoc.exists()) {
        setServices(servicesDoc.data().items);
      }

      const contactDoc = await getDoc(doc(collection(db, 'settings'), 'contact'));
      if (contactDoc.exists()) {
        setContactInfo(contactDoc.data());
      }

      const packagesDoc = await getDoc(doc(collection(db, 'settings'), 'packages'));
      if (packagesDoc.exists()) {
        setPackages(packagesDoc.data().items);
      }

      const ourStoryDoc = await getDoc(doc(collection(db, 'settings'), 'ourStory'));
      if (ourStoryDoc.exists()) {
        setOurStory(ourStoryDoc.data().story);
      }

      const testimonialsDoc = await getDoc(doc(collection(db, 'settings'), 'testimonials'));
      if (testimonialsDoc.exists()) {
        setTestimonials(testimonialsDoc.data().items);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const handleLogin = async () => {
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Error logging in with Google:', error);
    }
  };

  const handleHeaderSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(collection(db, 'settings'), 'header');
      await setDoc(docRef, {
        title: headerTitle,
        subtitle: headerSubtitle,
        button: headerButton
      }, { merge: true });
      alert('Header successfully updated!');
    } catch (error) {
      console.error('Error updating header: ', error);
    }
  };

  const handleAdvantagesSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(collection(db, 'settings'), 'advantages');
      await setDoc(docRef, {
        items: advantages
      }, { merge: true });
      alert('Advantages successfully updated!');
    } catch (error) {
      console.error('Error updating advantages: ', error);
    }
  };

  const handleServicesSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(collection(db, 'settings'), 'services');
      await setDoc(docRef, {
        items: services
      }, { merge: true });
      alert('Services successfully updated!');
    } catch (error) {
      console.error('Error updating services: ', error);
    }
  };

  const handlePackagesSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(collection(db, 'settings'), 'packages');
      await setDoc(docRef, {
        items: packages
      }, { merge: true });
      alert('Packages successfully updated!');
    } catch (error) {
      console.error('Error updating packages: ', error);
    }
  };

  const handleContactSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(collection(db, 'settings'), 'contact');
      await setDoc(docRef, {
        email: contactInfo.email,
        phone: contactInfo.phone
      }, { merge: true });
      alert('Contact info successfully updated!');
    } catch (error) {
      console.error('Error updating contact info: ', error);
    }
  };

  const handleOurStorySubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(collection(db, 'settings'), 'ourStory');
      await setDoc(docRef, {
        story: ourStory
      }, { merge: true });
      alert('Our Story successfully updated!');
    } catch (error) {
      console.error('Error updating Our Story: ', error);
    }
  };

  const handleTestimonialsSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(collection(db, 'settings'), 'testimonials');
      await setDoc(docRef, {
        items: testimonials
      }, { merge: true });
      alert('Testimonials successfully updated!');
    } catch (error) {
      console.error('Error updating testimonials: ', error);
    }
  };

  const addAdvantage = () => {
    setAdvantages([...advantages, { title: '', description: '' }]);
  };

  const deleteAdvantage = (index) => {
    const newAdvantages = advantages.filter((_, i) => i !== index);
    setAdvantages(newAdvantages);
  };

  const addService = () => {
    setServices([...services, { title: '', description: '' }]);
  };

  const deleteService = (index) => {
    const newServices = services.filter((_, i) => i !== index);
    setServices(newServices);
  };

  const addPackage = () => {
    setPackages([...packages, { name: '', items: [''] }]);
  };

  const deletePackage = (index) => {
    const newPackages = packages.filter((_, i) => i !== index);
    setPackages(newPackages);
  };

  const addPackageItem = (packageIndex) => {
    const newPackages = [...packages];
    newPackages[packageIndex].items.push('');
    setPackages(newPackages);
  };

  const deletePackageItem = (packageIndex, itemIndex) => {
    const newPackages = [...packages];
    newPackages[packageIndex].items = newPackages[packageIndex].items.filter((_, i) => i !== itemIndex);
    setPackages(newPackages);
  };

  const addTestimonial = () => {
    setTestimonials([...testimonials, { text: '', author: '' }]);
  };

  const deleteTestimonial = (index) => {
    const newTestimonials = testimonials.filter((_, i) => i !== index);
    setTestimonials(newTestimonials);
  };

  return (
    <div>
      {!user ? (
        <div>
          <h2>Login to Access Admin Page</h2>
          <button onClick={handleLogin}>Login with Google</button>
        </div>
      ) : (
        <div className='admin'>
          <h2>Admin Page</h2>
          <Tabs defaultActiveKey="header" id="admin-tabs">
            <Tab eventKey="header" title="Header">
              <form onSubmit={handleHeaderSubmit}>
                <div className="item">
                  <span>H1</span>
                  <input
                    type="text"
                    placeholder="Header Title"
                    value={headerTitle}
                    onChange={(e) => setHeaderTitle(e.target.value)}
                    required
                  />
                </div>
                <div className="item">
                  <span>H2</span>
                  <input
                    type="text"
                    placeholder="Header Subtitle"
                    value={headerSubtitle}
                    onChange={(e) => setHeaderSubtitle(e.target.value)}
                    required
                  />
                </div>
                <div className='item'>
                  <span>CTA</span>
                  <input
                    type="text"
                    placeholder="Header Button Text"
                    value={headerButton}
                    onChange={(e) => setHeaderButton(e.target.value)}
                    required
                  />
                </div>
                <button type="submit">Update Header</button>
              </form>
            </Tab>
            <Tab eventKey="advantages" title="Advantages">
              <form onSubmit={handleAdvantagesSubmit}>
                {advantages.map((advantage, index) => (
                  <div className="item" key={index}>
                    <input
                      type="text"
                      placeholder={`Advantage ${index + 1} Title`}
                      value={advantage.title}
                      onChange={(e) => {
                        const newAdvantages = [...advantages];
                        newAdvantages[index].title = e.target.value;
                        setAdvantages(newAdvantages);
                      }}
                      required
                    />
                    <input
                      type="text"
                      placeholder={`Advantage ${index + 1} Description`}
                      value={advantage.description}
                      onChange={(e) => {
                        const newAdvantages = [...advantages];
                        newAdvantages[index].description = e.target.value;
                        setAdvantages(newAdvantages);
                      }}
                      required
                    />
                    <button type="button" onClick={() => deleteAdvantage(index)}>Delete</button>
                  </div>
                ))}
                <button type="button" onClick={addAdvantage}>Add Advantage</button>
                <button type="submit">Update Advantages</button>
              </form>
            </Tab>
            <Tab eventKey="services" title="Services">
              <form onSubmit={handleServicesSubmit}>
                {services.map((service, index) => (
                  <div className="item" key={index}>
                    <input
                      type="text"
                      placeholder={`Service ${index + 1} Title`}
                      value={service.title}
                      onChange={(e) => {
                        const newServices = [...services];
                        newServices[index].title = e.target.value;
                        setServices(newServices);
                      }}
                      required
                    />
                    <input
                      type="text"
                      placeholder={`Service ${index + 1} Description`}
                      value={service.description}
                      onChange={(e) => {
                        const newServices = [...services];
                        newServices[index].description = e.target.value;
                        setServices(newServices);
                      }}
                      required
                    />
                    <button type="button" onClick={() => deleteService(index)}>Delete</button>
                  </div>
                ))}
                <button type="button" onClick={addService}>Add Service</button>
                <button type="submit">Update Services</button>
              </form>
            </Tab>
            <Tab eventKey="packages" title="Packages">
              <form onSubmit={handlePackagesSubmit}>
                {packages.map((packageItem, index) => (
                  <div className="item" key={index}>
                    <input
                      type="text"
                      placeholder={`Package ${index + 1} Name`}
                      value={packageItem.name}
                      onChange={(e) => {
                        const newPackages = [...packages];
                        newPackages[index].name = e.target.value;
                        setPackages(newPackages);
                      }}
                      required
                    />
                    {packageItem.items.map((item, itemIndex) => (
                      <div key={itemIndex}>
                        <input
                          type="text"
                          placeholder={`Package ${index + 1} Item ${itemIndex + 1}`}
                          value={item}
                          onChange={(e) => {
                            const newPackages = [...packages];
                            newPackages[index].items[itemIndex] = e.target.value;
                            setPackages(newPackages);
                          }}
                          required
                        />
                        <button type="button" onClick={() => deletePackageItem(index, itemIndex)}>Delete Item</button>
                      </div>
                    ))}
                    <button type="button" onClick={() => addPackageItem(index)}>Add Item</button>
                    <button type="button" onClick={() => deletePackage(index)}>Delete Package</button>
                  </div>
                ))}
                <button type="button" onClick={addPackage}>Add Package</button>
                <button type="submit">Update Packages</button>
              </form>
            </Tab>
            <Tab eventKey="contact" title="Contact Info">
              <form onSubmit={handleContactSubmit}>
                <div className="item">
                  <input
                    type="text"
                    placeholder="Contact Email"
                    value={contactInfo.email}
                    onChange={(e) => setContactInfo({ ...contactInfo, email: e.target.value })}
                    required
                  />
                  <input
                    type="text"
                    placeholder="Contact Phone"
                    value={contactInfo.phone}
                    onChange={(e) => setContactInfo({ ...contactInfo, phone: e.target.value })}
                    required
                  />
                </div>
                <button type="submit">Update Contact Info</button>
              </form>
            </Tab>
            <Tab eventKey="our-story" title="Our Story">
              <form onSubmit={handleOurStorySubmit}>
                <textarea
                  placeholder="Our Story"
                  value={ourStory}
                  onChange={(e) => setOurStory(e.target.value)}
                  required
                />
                <button type="submit">Update Our Story</button>
              </form>
            </Tab>
            <Tab eventKey="testimonials" title="Testimonials">
              <form onSubmit={handleTestimonialsSubmit}>
                {testimonials.map((testimonial, index) => (
                  <div className="item" key={index}>
                    <input
                      type="text"
                      placeholder={`Testimonial ${index + 1} Text`}
                      value={testimonial.text}
                      onChange={(e) => {
                        const newTestimonials = [...testimonials];
                        newTestimonials[index].text = e.target.value;
                        setTestimonials(newTestimonials);
                      }}
                      required
                    />
                    <input
                      type="text"
                      placeholder={`Testimonial ${index + 1} Author`}
                      value={testimonial.author}
                      onChange={(e) => {
                        const newTestimonials = [...testimonials];
                        newTestimonials[index].author = e.target.value;
                        setTestimonials(newTestimonials);
                      }}
                      required
                    />
                    <button type="button" onClick={() => deleteTestimonial(index)}>Delete</button>
                  </div>
                ))}
                <button type="button" onClick={addTestimonial}>Add Testimonial</button>
                <button type="submit">Update Testimonials</button>
              </form>
            </Tab>
          </Tabs>
        </div>
      )}
    </div>
  );
}

export default Admin;
