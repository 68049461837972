// src/firebaseConfig.js

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth"; // Import Auth and GoogleAuthProvider

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyClXzE4fSdIkWjxOociqs1FKnW1YEKhCaY",
  authDomain: "chen-751b5.firebaseapp.com",
  projectId: "chen-751b5",
  storageBucket: "chen-751b5.appspot.com",
  messagingSenderId: "928701281245",
  appId: "1:928701281245:web:79d1514b3a0e0248363c34",
  measurementId: "G-DRT1M07LKZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app); // Initialize Auth
const provider = new GoogleAuthProvider(); // Initialize GoogleAuthProvider

export { app, analytics, db, auth, provider };
