import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Lottie from 'react-lottie';
import '../Css/Contact.css';
import sentmail from '../Assets/sentmail.json';

const Contact = ({ contactInfo }) => {
  const [status, setStatus] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    console.log('Form submitted'); // Log when the form is submitted

    emailjs.sendForm('service_r5zeonr', 'template_9xegpj7', form, 'MPBUp54ojf9uv3q3O')
      .then((result) => {
        console.log('Email sent', result.text); // Log the result
        setIsLoading(true);
        setTimeout(() => {
          setStatus('Thank you! We will contact you shortly.');
          setIsSubmitted(true);
          setIsLoading(false);
        }, 3000);
      }, (error) => {
        console.error('Email error:', error.text); // Log any errors
        setIsLoading(true);
        setTimeout(() => {
          setStatus('Failed to send message.');
          setIsSubmitted(true);
          setIsLoading(false);
        }, 3000);
      });

    form.reset();
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: sentmail,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className='home' id='contact'>
      <header className='contact_text'>
        <h1>Contact Us</h1>
        <p>We would love to hear from you!</p>
      </header>
      <div className='contact'>  
        <section>
          <h2>Send us a message</h2>
          {!isSubmitted && !isLoading ? (
            <form onSubmit={handleSubmit}>
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" required />

              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" required />

              <label htmlFor="message">Message:</label>
              <textarea id="message" name="message" required></textarea>

              <button type="submit">Submit</button>
            </form>
          ) : isLoading ? (
            <div className="loading">
              <Lottie options={defaultOptions} height={150} width={150} />
            </div>
          ) : (
            <h5>{status}</h5>
          )}
        </section>

        {(contactInfo.email || contactInfo.phone) && (
          <section>
            <h2>Contact Information</h2>
            {contactInfo.email && <p>Email: {contactInfo.email}</p>}
            {contactInfo.phone && <p>Phone: {contactInfo.phone}</p>}
          </section>
        )}
      </div>
    </div>
  );
}

export default Contact;
