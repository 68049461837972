// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Comps/Home';
import Nav from './Comps/Nav';
import Footer from './Comps/Footer';
import Admin from './Comps/Admin'; 

function App() {
  return (
    <Router>
      <div className="App">
        <Nav />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/admin" element={<Admin />} /> 
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
