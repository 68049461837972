import React, { useState } from 'react';
import '../Css/Nav.css';
import logo  from '../Assets/Logo.png'

const Nav = () => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };
  
    return (
      <nav className="navigation">
        <div className="nav-container">
          <div className="nav-logo">
            <a href="/">
              <img src={logo} alt='logo' />
            </a>
          </div>
          <div className={`nav-toggle ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
            <div className="hamburger"></div>
            <div className="hamburger"></div>
            <div className="hamburger"></div>
          </div>
        </div>
        <div className={`nav-menu ${isOpen ? 'open' : ''}`}>
          <ul>
            <li><a href="/#home">Home</a></li>
            <li><a href="/#services">Services</a></li>
            <li><a href="/#contact">Contact</a></li>
            <li><a href="/#Our story">Our story</a></li>
          </ul>
        </div>
      </nav>
    );
  }
  
  export default Nav;
