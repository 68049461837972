import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <p>&copy; {currentYear} Villa Management Services. All rights reserved.</p>
      <ul>
        <li><a href="https://facebook.com">Facebook</a></li>
        <li><a href="https://instagram.com">Instagram</a></li>
      </ul>
      <span className="powered-by" style={{fontSize:'small'}}>Powered by
      <a href="https://www.linkedin.com/in/ofri-yehuda/"
      target="_blank" rel="noopener noreferrer" style={{color:'white', marginLeft:'10px'}}>
      Ofri Yehuda</a></span>
    </footer>
  );
}

export default Footer;
